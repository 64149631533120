import React from "react"
import Layout from "../containers/Layout";
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import SEO from "../components/genericComponents/SEO";

const PolicyCookiesPage = props => {
  const { t } = useTranslation()

  return (
    <Layout
      navBarProps={{
        navClass: "is--white",
      }}
      logoClassName={"filterClass"}
      path={props.path}
      optionsBooking={{ path: props.path }}
    >
       <SEO
        title={t('seo_policity_cookies_title')}
        desc={t('seo_policity_cookies_description')}
        pathname={"/politica-cookies/"}
        article={false}
      />
      <section className="terms-content container-generic" data-scroll-section>
        <h1>{t("policty_cookies_title")}</h1>
        <p>{t('policty_cookies_content')}</p>
        <h3>{t('policty_cookies_section_title1')}</h3>
        <p>{t('policty_cookies_section_content1')}</p>
        <h3>{t('policty_cookies_section_title2')}</h3>
        <ul>
            <li>{t('policty_cookies_section_content2_cookie1')}</li>
            <li>{t('policty_cookies_section_content2_cookie2')}</li>
          </ul>
        <h3>{t('policty_cookies_section_title3')}</h3>
        <p>{t('policty_cookies_section_content3')}</p>
        <ul>
          <li>{t('policty_cookies_section_content3_1')}</li>
          <ul>
            <li>Firefox: http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-web</li>
            <li>Chrome: http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</li>
            <li>Explorer: https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</li>
            <li>Safari: http://support.apple.com/kb/ph5042</li>
          </ul>
          <li>{t('policty_cookies_section_content3_2')}</li>
          <li>{t('policty_cookies_section_content3_3')}</li>
        </ul>
        <h3>{t('policty_cookies_section_title4')}</h3>
        <p>{t('policty_cookies_section_content4')}</p>
      </section>
    </Layout>
  )
}

export default PolicyCookiesPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
